import moment from "moment";

export default () => {
  const formatDate = (value) => {
    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const zeroFormat = (value) => `${value < 10 ? "0" : ""}${value}`;

    return [zeroFormat(day), zeroFormat(month), year].join(".");
  };

  const getWeekNumber = (value) => {
    return moment(value).format("WW");
  };

  const getDataPickerDateFormat = (value) => {
    if (!value) {
      return;
    }
    return moment(value).format("YYYY-MM-DD");
  };

  const getDateWithFormat = (value, format) => {
    if (!value || !format) {
      return;
    }
    return moment(value).format(format);
  };

  const getDateDurationAgo = (value) => {
    return moment(value).fromNow();
  };

  const compareDatesDescByDateTo = (a, b) => {
    if (a.is_present && b.is_present) return 0;
    if (!a.is_present && b.is_present) return 1;
    if (a.is_present && !b.is_present) return -1;
    if (new Date(a?.date_to) < new Date(b?.date_to)) return 1;
    if (new Date(a?.date_to) > new Date(b?.date_to)) return -1;
    if (new Date(a?.date_to) === new Date(b?.date_to)) {
      if (new Date(a?.date_from) < new Date(b?.date_from)) return 1;
      if (new Date(a?.date_from) > new Date(b?.date_from)) return -1;
      return 0;
    }
    return 0;
  };

  const snakeCase = (string) => {
    return string
      .replace(/\d+/g, " ")
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("_");
  };

  const generateId = () => {
    return `${Math.random().toString(36).substring(2)}`;
  };

  const scrollToFirstError = (errorList) => {
    const errorElements = document.getElementsByName(Object.keys(errorList)[0]);
    console.log(errorElements[0]);
    if (errorElements.length) {
      errorElements[0].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  const readableFileSize = (bytes) => {
    if (bytes === 0) {
      return "0.00 B";
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
    );
  };

  const colorByRating = (rating) => {
    switch (rating) {
      case "A_PLUS": {
        return "bgGreen8";
      }
      case "A": {
        return "bgGreen2";
      }
      case "B": {
        return "bgBlue5";
      }
      case "C": {
        return "bgOrange6";
      }
      default:
        return "bgOrange6";
    }
  };

  const candidateStageTo = (from) => {
    switch (from) {
      case "search": {
        return "offer";
      }
      case "offer": {
        return "offer_confirmed";
      }
      case "offer_confirmed": {
        return "order";
      }
      case "order": {
        return "order_confirmed";
      }
      case "order_confirmed": {
        return "finish";
      }
      case "finish": {
        return "hired";
      }
      default: {
        return "search";
      }
    }
  };
  const candidateStageToRequestStep = (stage) => {
    if (["search", "offer"].includes(stage)) {
      return "search";
    }
    if (["offer_confirmed", "order"].includes(stage)) {
      return "offer";
    }
    if (["order_confirmed"].includes(stage)) {
      return "order";
    }
    // if (["finish"].includes(stage)) {
    //   return "finish"
    // }
    return "";
  };

  const candidateStageToRequestStepForTableCell = (stage) => {
    if (["search"].includes(stage)) {
      return "Search";
    }
    if (["offer_confirmed", "offer"].includes(stage)) {
      return "Offer";
    }
    if (["order_confirmed", "order"].includes(stage)) {
      return "Order";
    }
    if (stage === "finish") {
      return "Finish";
    }
    if (stage === "hired") {
      return "Hired";
    }
    if (stage === "changed") {
      return "Changed";
    }
    return "";
  };

  const candidateStageIconForTableCell = (stage) => {
    if (["search"].includes(stage)) {
      return "searchPurple8";
    }
    if (["offer_confirmed", "offer"].includes(stage)) {
      return "offerBlue95";
    }
    if (["order_confirmed", "order"].includes(stage)) {
      return "orderBlue5";
    }
    if (stage === "finish") {
      return "finishGreen8";
    }
    if (stage === "hired") {
      return "hiredPurple8";
    }
    if (stage === "changed") {
      return "updateOrange";
    }
    return "";
  };

  return {
    snakeCase,
    getWeekNumber,
    getDataPickerDateFormat,
    getDateWithFormat,
    getDateDurationAgo,
    compareDatesDescByDateTo,
    formatDate,
    generateId,
    scrollToFirstError,
    readableFileSize,
    colorByRating,
    candidateStageTo,
    candidateStageToRequestStep,
    candidateStageToRequestStepForTableCell,
    candidateStageIconForTableCell,
  };
};
