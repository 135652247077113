<template>
  <JInput
    v-if="false"
    class-wrap="wrapDropdownCustom2 wrapSearchPage mb-3 w-100"
    class="selectInput"
    :placeholder="placeholder"
  >
    <template #after>
      <button class="btnNone btnSearchPage" />
      <div class="dropdownSelect my-1 w-100 dropdownCustom overflow-hidden">
        <div class="selectCustomTop w-100 d-inline-block">
          <div class="wrapSelectList scrollGray w-100">
            <div class="selectList pointer active w-100">40</div>
            <div class="selectList pointer w-100">50</div>
            <div class="selectList pointer w-100">56</div>
            <div class="selectList pointer w-100">40</div>
            <div class="selectList pointer w-100">50</div>
            <div class="selectList pointer w-100">56</div>
            <div class="selectList pointer w-100">40</div>
            <div class="selectList pointer w-100">50</div>
            <div class="selectList pointer w-100">56</div>
            <div class="selectList pointer w-100">Other</div>
          </div>
        </div>
      </div>
    </template>
  </JInput>
</template>

<script lang="ts">
import JInput from "~/shared/ui/Input.vue";

export default defineComponent({
  name: "JSearch",
  components: { JInput },
  props: {
    placeholder: {
      type: String,
      default: "Find...",
    },
  },
});
</script>
