import { default as additionalzRBdP28jvwMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/additional.vue?macro=true";
import { default as cvWiXtUc04WSMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/cv.vue?macro=true";
import { default as indexnzAwPn4BlAMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/index.vue?macro=true";
import { default as personal_45cardCM01mOBJ7NMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/personal-card.vue?macro=true";
import { default as profile4sICysZ8USMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/profile.vue?macro=true";
import { default as tests604N7CR60YMeta } from "/builds/jobbible/front/app/pages/candidates/[id]/tests.vue?macro=true";
import { default as index8bTCvpIjFgMeta } from "/builds/jobbible/front/app/pages/candidates/index.vue?macro=true";
import { default as dashboardMLQbudEXjKMeta } from "/builds/jobbible/front/app/pages/dashboard.vue?macro=true";
import { default as index94mHddeeSDMeta } from "/builds/jobbible/front/app/pages/index.vue?macro=true";
import { default as loginpXXdUopK4QMeta } from "/builds/jobbible/front/app/pages/login.vue?macro=true";
import { default as candidates4J4z8k8caAMeta } from "/builds/jobbible/front/app/pages/requests/candidates.vue?macro=true";
import { default as clientsovf61c0ndLMeta } from "/builds/jobbible/front/app/pages/requests/clients.vue?macro=true";
import { default as styleguidehRv3aGMVqvMeta } from "/builds/jobbible/front/app/pages/styleguide.vue?macro=true";
import { default as vacancyM2OEc1tQ0tMeta } from "/builds/jobbible/front/app/pages/vacancy.vue?macro=true";
import { default as vacancyOldKnqXrT8Vr2Meta } from "/builds/jobbible/front/app/pages/vacancyOld.vue?macro=true";
export default [
  {
    name: additionalzRBdP28jvwMeta?.name ?? "candidates-id-additional",
    path: additionalzRBdP28jvwMeta?.path ?? "/candidates/:id()/additional",
    meta: additionalzRBdP28jvwMeta || {},
    alias: additionalzRBdP28jvwMeta?.alias || [],
    redirect: additionalzRBdP28jvwMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/additional.vue").then(m => m.default || m)
  },
  {
    name: cvWiXtUc04WSMeta?.name ?? "candidates-id-cv",
    path: cvWiXtUc04WSMeta?.path ?? "/candidates/:id()/cv",
    meta: cvWiXtUc04WSMeta || {},
    alias: cvWiXtUc04WSMeta?.alias || [],
    redirect: cvWiXtUc04WSMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/cv.vue").then(m => m.default || m)
  },
  {
    name: indexnzAwPn4BlAMeta?.name ?? "candidates-id",
    path: indexnzAwPn4BlAMeta?.path ?? "/candidates/:id()",
    meta: indexnzAwPn4BlAMeta || {},
    alias: indexnzAwPn4BlAMeta?.alias || [],
    redirect: indexnzAwPn4BlAMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: personal_45cardCM01mOBJ7NMeta?.name ?? "candidates-id-personal-card",
    path: personal_45cardCM01mOBJ7NMeta?.path ?? "/candidates/:id()/personal-card",
    meta: personal_45cardCM01mOBJ7NMeta || {},
    alias: personal_45cardCM01mOBJ7NMeta?.alias || [],
    redirect: personal_45cardCM01mOBJ7NMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/personal-card.vue").then(m => m.default || m)
  },
  {
    name: profile4sICysZ8USMeta?.name ?? "candidates-id-profile",
    path: profile4sICysZ8USMeta?.path ?? "/candidates/:id()/profile",
    meta: profile4sICysZ8USMeta || {},
    alias: profile4sICysZ8USMeta?.alias || [],
    redirect: profile4sICysZ8USMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/profile.vue").then(m => m.default || m)
  },
  {
    name: tests604N7CR60YMeta?.name ?? "candidates-id-tests",
    path: tests604N7CR60YMeta?.path ?? "/candidates/:id()/tests",
    meta: tests604N7CR60YMeta || {},
    alias: tests604N7CR60YMeta?.alias || [],
    redirect: tests604N7CR60YMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/[id]/tests.vue").then(m => m.default || m)
  },
  {
    name: index8bTCvpIjFgMeta?.name ?? "candidates",
    path: index8bTCvpIjFgMeta?.path ?? "/candidates",
    meta: index8bTCvpIjFgMeta || {},
    alias: index8bTCvpIjFgMeta?.alias || [],
    redirect: index8bTCvpIjFgMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/candidates/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardMLQbudEXjKMeta?.name ?? "dashboard",
    path: dashboardMLQbudEXjKMeta?.path ?? "/dashboard",
    meta: dashboardMLQbudEXjKMeta || {},
    alias: dashboardMLQbudEXjKMeta?.alias || [],
    redirect: dashboardMLQbudEXjKMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index94mHddeeSDMeta?.name ?? "index",
    path: index94mHddeeSDMeta?.path ?? "/",
    meta: index94mHddeeSDMeta || {},
    alias: index94mHddeeSDMeta?.alias || [],
    redirect: index94mHddeeSDMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginpXXdUopK4QMeta?.name ?? "login",
    path: loginpXXdUopK4QMeta?.path ?? "/login",
    meta: loginpXXdUopK4QMeta || {},
    alias: loginpXXdUopK4QMeta?.alias || [],
    redirect: loginpXXdUopK4QMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: candidates4J4z8k8caAMeta?.name ?? "requests-candidates",
    path: candidates4J4z8k8caAMeta?.path ?? "/requests/candidates",
    meta: candidates4J4z8k8caAMeta || {},
    alias: candidates4J4z8k8caAMeta?.alias || [],
    redirect: candidates4J4z8k8caAMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/requests/candidates.vue").then(m => m.default || m)
  },
  {
    name: clientsovf61c0ndLMeta?.name ?? "requests-clients",
    path: clientsovf61c0ndLMeta?.path ?? "/requests/clients",
    meta: clientsovf61c0ndLMeta || {},
    alias: clientsovf61c0ndLMeta?.alias || [],
    redirect: clientsovf61c0ndLMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/requests/clients.vue").then(m => m.default || m)
  },
  {
    name: styleguidehRv3aGMVqvMeta?.name ?? "styleguide",
    path: styleguidehRv3aGMVqvMeta?.path ?? "/styleguide",
    meta: styleguidehRv3aGMVqvMeta || {},
    alias: styleguidehRv3aGMVqvMeta?.alias || [],
    redirect: styleguidehRv3aGMVqvMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/styleguide.vue").then(m => m.default || m)
  },
  {
    name: vacancyM2OEc1tQ0tMeta?.name ?? "vacancy",
    path: vacancyM2OEc1tQ0tMeta?.path ?? "/vacancy",
    meta: vacancyM2OEc1tQ0tMeta || {},
    alias: vacancyM2OEc1tQ0tMeta?.alias || [],
    redirect: vacancyM2OEc1tQ0tMeta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/vacancy.vue").then(m => m.default || m)
  },
  {
    name: vacancyOldKnqXrT8Vr2Meta?.name ?? "vacancyOld",
    path: vacancyOldKnqXrT8Vr2Meta?.path ?? "/vacancyOld",
    meta: vacancyOldKnqXrT8Vr2Meta || {},
    alias: vacancyOldKnqXrT8Vr2Meta?.alias || [],
    redirect: vacancyOldKnqXrT8Vr2Meta?.redirect || undefined,
    component: () => import("/builds/jobbible/front/app/pages/vacancyOld.vue").then(m => m.default || m)
  }
]