import plugin_vue3_A0OWXRrUgq from "/builds/jobbible/front/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/jobbible/front/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/builds/jobbible/front/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/jobbible/front/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/builds/jobbible/front/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/builds/jobbible/front/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import breadcrumb_NUTPyfcBQR from "/builds/jobbible/front/app/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/breadcrumb.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/jobbible/front/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import global_components_PEc2PKHunz from "/builds/jobbible/front/app/plugins/global-components.ts";
import quilleditor_client_gzCwdFSxDr from "/builds/jobbible/front/app/plugins/quilleditor.client.js";
import datepicker_QdRP4jKyEy from "/builds/jobbible/front/app/plugins/datepicker.js";
import sentry_client_shVUlIjFLk from "/builds/jobbible/front/app/plugins/sentry.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  breadcrumb_NUTPyfcBQR,
  chunk_reload_client_UciE0i6zes,
  global_components_PEc2PKHunz,
  quilleditor_client_gzCwdFSxDr,
  datepicker_QdRP4jKyEy,
  sentry_client_shVUlIjFLk
]