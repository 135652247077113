import MyNuxtLink from "~/components/MyNuxtLink";
import JButton from "~/shared/ui/Button.vue";
import JPagination from "~/shared/ui/Pagination.vue";
import JInput from "~/shared/ui/Input.vue";
import JSearch from "~/shared/ui/Search.vue";

const components = {
  MyNuxtLink,
  JButton,
  JInput,
  JPagination,
  JSearch,
};

export default defineNuxtPlugin({
  setup(app) {
    Object.entries(components).forEach(([name, component]) => {
      app.vueApp.component(name, component);
    });
  },
});
