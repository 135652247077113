<template>
  <div>
    <b-pagination
      v-if="currentPage && lastPage > 1"
      v-model="currentPage"
      page-class="btnPagination d-inline-block w-auto text-center fw-DMSans-4 mt-3 mx-2 p-0"
      first-class="btnPagination arrowPagination d-inline-block w-auto text-center fw-DMSans-4 mt-3 mx-2 p-0"
      last-class="btnPagination arrowPagination d-inline-block w-auto text-center fw-DMSans-4 mt-3 mx-2 p-0"
      next-class="btnPagination arrowPagination d-inline-block w-auto text-center fw-DMSans-4 mt-3 mx-2 p-0"
      prev-class="btnPagination arrowPagination d-inline-block w-auto text-center fw-DMSans-4 mt-3 mx-2 p-0"
      :total-rows="totalRows"
      :disabled="disabled"
      :per-page="perPage"
      class="row px-2 mainPagination justify-content-center"
    />
  </div>
</template>

<script>
export default {
  name: "JPagination",
  props: {
    meta: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.meta?.current_page || null;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    totalRows() {
      return Number(this.meta.total);
    },
    lastPage() {
      return Number(this.meta.last_page);
    },
    perPage() {
      return Number(this.meta.per_page);
    },
  },
};
</script>
