<template>
  <b-button v-bind="bootstrapButton">
    <slot>{{ text }}</slot>
  </b-button>
</template>

<script lang="ts">
import { PropType } from "vue";
import { ButtonType, ButtonVariant } from "bootstrap-vue-next/src/types";

export default defineComponent({
  name: "JButton",
  props: {
    text: {
      type: String as PropType<string>,
      default: "",
    },
    variant: {
      type: String as PropType<ButtonVariant>,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { bootstrapButton: props };
  },
});
</script>
