<template>
  <div
    v-click-outside="hideSuggestion"
    class="formGroup position-relative"
    :class="classWrap"
  >
    <label
      v-if="label"
      :for="inputId"
      class="mainFormLabel w-100 mb-1"
      v-text="label"
    />
    <VField
      v-slot="{ field }"
      v-model="model"
      :name="name"
      :validate-on-input="true"
    >
      <div
        class="wrapForm w-100 position-relative"
        :class="[
          classForm,
          {
            'wrapSelect wrapDropdownCustom': suggestions.length,
            active: isOpenSuggestions,
          },
        ]"
      >
        <input
          :id="inputId"
          v-bind="Object.assign({}, field, $attrs)"
          ref="inputRef"
          class="mainForm w-100"
          :placeholder="placeholder"
          :minlength="minLength"
          :maxlength="maxLength"
          @click="inputClick"
        />
        <div
          v-if="hasSuggestion"
          class="dropdownSelect my-1 w-100 dropdownCustom overflow-hidden"
        >
          <div class="selectCustomTop w-100 d-inline-block">
            <div class="wrapSelectList scrollGray w-100">
              <div
                v-for="suggestion in suggestions"
                :key="`suggestion-${suggestion}`"
                class="selectList pointer w-100"
                :class="{ active: model === suggestion }"
                @click="select(suggestion)"
                v-text="suggestion"
              />
              <div
                class="selectList pointer w-100"
                :class="{ active: !suggestions.includes(model) }"
                @click="select(null)"
              >
                Other
              </div>
            </div>
          </div>
        </div>
        <slot name="after" />
      </div>
      <VErrorMessage class="error-message" :name="name" />
    </VField>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import ClassTypeProp from "~/shared/lib/ClassTypeProp";
import useHelper from "~/composables/useHelper";
// @ts-ignore
import vClickOutside from "click-outside-vue3";

type SuggestionType = number | string;

export default defineComponent({
  name: "JInput",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  inheritAttrs: false,
  props: {
    classWrap: {
      type: ClassTypeProp,
      default: "",
    },
    classForm: {
      type: ClassTypeProp,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number] as PropType<string | number | null>,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    suggestions: {
      type: Array as PropType<SuggestionType[]>,
      default: () => [],
    },
    minLength: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
  emits: ["update:modelValue", "inputClick"],
  setup(props, { emit }) {
    const inputId = useHelper().generateId();
    const isOpenSuggestions = ref(false);
    const inputRef = ref();

    const model = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const hasSuggestion = computed(() => props.suggestions.length > 0);

    const inputClick = (e: any) => {
      toggleSuggestion();
      emit("inputClick", e);
    };

    const onInput = (e: any) => {
      model.value = e.target.value;
    };

    const select = (suggestion: SuggestionType | null) => {
      model.value = suggestion;
      if (suggestion === null) {
        inputRef.value.focus();
      } else {
        toggleSuggestion(false);
      }
    };

    const toggleSuggestion = (force: boolean | undefined = undefined) => {
      if (!hasSuggestion.value) {
        return;
      }
      isOpenSuggestions.value =
        force === undefined ? !isOpenSuggestions.value : force;
    };

    const hideSuggestion = () => toggleSuggestion(false);

    return {
      inputId,
      model,
      inputClick,
      onInput,
      hasSuggestion,
      isOpenSuggestions,
      select,
      toggleSuggestion,
      hideSuggestion,
      inputRef,
    };
  },
});
</script>
